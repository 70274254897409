import 'core-js'
import React, { useEffect, useState, useRef } from 'react'
import { Card, IconButton, Dialog, Button, DialogTitle, Divider, Tooltip, CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import EventIcon from '@material-ui/icons/Event'
import { Integrations } from '@sentry/tracing'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import EmailIcon from '@material-ui/icons/Email'
import FullScreenDialog from './components/FullScreenDialog'
import CalendarDialog from './components/CalendarDialog'
import MeetingBookedDialog from './components/MeetingBookedDialog'
import './main.css'
import { _getSlots, getDaysDict } from './api/calendars'
import ContactInfoIcon from 'url:./img/contact_info.png'
import SignalsLogo from 'url:./img/signals_logo.png'
import LocationIcon from 'url:./img/location.png'
import Icon from './components/Icon'
import ExpandedInfo from './components/ExpandedInfo'

const cfUsers = [
  'billy-bateman',
  'jeffrey-mcclellan',
  'adam-wall',
  'jordan-crandell',
  'diego-vanegas',
  'damarius-koy-henderson',
  'alex-durrant',
  'jeremiah-johnson',
  'ethan-ashton',
  'taylor-fletcher-1',
  'jenny-young',
  'mike-nash',
  'evan-mckague',
  'david-elkington',
  'mark-bryson',
  'tanner-sundwall',
  'colton-yourell',
  'jake-johnson',
  'pete-ketchum',
  'ronald-lopez',
  'colin-dunn'
]
const appURLs = {
  dev: 'local.chatfunnels.dev:12345',
  beta: 'beta.chatfunnels.com',
  prod: 'app.chatfunnels.com'
}

const url = new URL(window.location.href)
const subdomain = url.hostname.split('.')[0]

let env = 'prod'
let sampleRate = 0.1
let referenceCode = url.pathname.replace(/^\//, '')
if (url.hostname === 'local.chatfunnels.dev') {
  env = 'dev'
  sampleRate = 1.0
} else if (url.pathname.startsWith('/0/') || cfUsers.includes(referenceCode)) {
  // The hard-coded list of cfUsers is for backwards compatilibity. Moving forward, the
  // generated beta URLs will include a /0/ prefix, but just in case URLs without that
  // prefix have been distributed, this will allow the old urls to work
  env = 'beta'
  if (url.pathname.startsWith('/0/')) {
    referenceCode = url.pathname.substr(3)
  }
}

const appURL = appURLs[env]
Sentry.init({
  dsn: 'https://5416834f1ce747eb91aa3cb9647c92f3@o265885.ingest.sentry.io/5465644',
  environment: env,
  integrations: [
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: sampleRate
})

const useStyles = makeStyles(theme => ({
  cardHeader: {
    height: 188,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: '1px solid #d9d9d9'
  },
  profileCard: {
    borderRadius: 10,
    margin: 40
  },
  aboutCard: {
    borderRadius: 10,
    margin: 40,
    paddingTop: 30,
    paddingLeft: 40
  },
  companyCard: {
    borderRadius: 10,
    margin: 40,
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 30,
    position: 'relative'
  },
  profileCardBody: {
    height: 200
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5'
  },
  container: {
    maxWidth: 800,
    display: 'block',
    marginLeft: 'auto',
    width: '100%',
    backgroundColor: '#F5F5F5'
  },
  sidebar: {
    width: 256,
    height: '100%',
    marginRight: 'auto',
    backgroundColor: '#F5F5F5',
    marginTop: '40px'
  },
  profilePicture: {
    height: 160,
    width: 160,
    position: 'absolute',
    top: 88,
    borderRadius: 150,
    border: '2px solid white',
    left: 28
  },
  subtitle: {
    fontSize: '14px',
    color: '#6B7280',
    marginTop: '5px'
  },
  agentName: {
    fontSize: '24px',
    fontWeight: 700
  },
  button: {
    marginRight: 5
  },
  smallScreenButton: {
    width: '100%',
    marginBottom: 10
  },
  companyPicture: {
    height: 32,
    width: 32,
    display: 'block',
    marginLeft: 'auto',
    objectFit: 'contain',
    marginRight: 'auto'
  },
  companyGrid: {
    display: 'grid',
    gridTemplateColumns: '150px auto',
    gridRowGap: '30px'
  },
  companyImageContainer: {
    width: 60,
    marginRight: 20,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 50,
    overflow: 'hidden'
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -15
  },
  link: {
    color: '#0CA6F9',
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'none'
  },
  attr: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#6B7280'
  }
}))

const SimpleDialog = props => {
  const classes = useStyles()
  const { onClose, open, profileInfo } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} fullWidth maxWidth='xs' open={open}>
      <DialogTitle disableTypography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 5, paddingLeft: 20 }}>
        <div style={{ fontSize: 18 }}>
          {profileInfo.attributes.agent_name}
        </div>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <div style={{ margin: 20, marginBottom: 30, marginLeft: 30 }}>
        <div style={{ display: 'flex' }}>
          <PhoneInTalkIcon style={{ marginRight: 15 }} />
          <div>
            <div>
              PHONE
            </div>
            <div>
              {profileInfo?.attributes?.phone}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <EmailIcon style={{ marginRight: 15 }} />
          <div>
            <div>
              EMAIL
            </div>
            <div>
              <a href={`mailto:${profileInfo.attributes.email}`}>{profileInfo.attributes.email}</a>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const App = props => {
  const classes = useStyles()
  const [profileInfo, setProfileInfo] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [calendarModalOpen, setCalendarModalOpen] = useState(false)
  const [meetingSelectorOpen, setMeetingSelectorOpen] = useState(false)
  const [meetingBookedModalOpen, setMeetingBookedModalOpen] = useState(false)
  const [confirmedDetails, setConfirmedDetails] = useState()
  const [meetingInfo, setMeetingInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [availabilityError, setAvailabilityError] = useState()
  const chatClientRef = useRef()
  const medium = useMediaQuery('(max-width: 1440px)')
  const small = useMediaQuery('(max-width: 550px)')

  // Calendar State
  const [timezone, setTimeZone] = useState()
  const [availableDates, setAvailableDates] = useState([])
  const [slots, setSlots] = useState()
  const [date, changeDate] = useState(new Date())
  const [aboutExpanded, setAboutExpanded] = useState(false)
  const [contactInfoExpanded, setContactInfoExpanded] = useState(false)

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 1100;

  const setSelectedCard = (key) => {
    const selectedCard = profileInfo.attributes.meeting_types.filter(mt => mt.id === key)[0]
    if (selectedCard) {
      setMeetingInfo(selectedCard)
      return selectedCard
    } else {
      return false
    }
  }

  const handleMeetingSelect = (meetingId) => {
    const card = setSelectedCard(meetingId)
    if (card) {
      setLoading(true)
      getTimeSlots(meetingId)
    }
  }

  const handleBookMeetingButtonClick = () => {
    if (profileInfo && profileInfo?.attributes?.meeting_types) {
      if (profileInfo.attributes.meeting_types.length === 1) {
        const meetingId = profileInfo.attributes.meeting_types[0].id
        if (meetingId) {
          handleMeetingSelect(meetingId)
        }
      } else {
        setMeetingSelectorOpen(true)
      }
    }
  }

  const getTimeSlots = (meetingTypeId) => {
    if (hasCalendar) {
      setAvailabilityError()
      _getSlots(
        profileInfo.attributes.user_id,
        profileInfo.attributes.tenant_id,
        appURL,
        meetingTypeId
      )
        .then(response => {
          const dict = getDaysDict(response.available_slots, timezone)
          const dates = Object.keys(dict)
          if (dates?.length) {
            changeDate(dates[0])
            setAvailableDates(dates)
            setSlots(dict)
            setCalendarModalOpen(true)
            setLoading(false)
          } else {
            setAvailabilityError('It seems there are no times available for this meeting type.')
            setLoading(false)
          }
        })
    }
  }

  useEffect(() => {
    const lookupUrl = `https://${appURL}/api/agent_profile_pages/quick_lookup`
    fetch(lookupUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Pragma: 'no-cache'
      },
      body: JSON.stringify({
        subdomain: subdomain,
        reference_code: referenceCode
      })
    })
      .then((response) => response.json())
      .then(response => {
        setProfileInfo(response.data)
        document.title = response.data.attributes.agent_name + "'s Signals Profile"
      })
  }, [])

  useEffect(() => {
    if (profileInfo) {
      const packetCode = profileInfo.attributes?.packet_code
      const loadPath = profileInfo.attributes?.load_path
      const servicePath = profileInfo.attributes?.service_path

      if (packetCode) {
        window.cf_chat_loader = () => {
          return {
            user_id: profileInfo.attributes?.user_id,
            appLoaded: (cf, e) => {
              setTimeout(() => {
                cf._internal.createChatWithUserId(profileInfo.attributes.user_id)
              }, 500)
            },
            disabled: true,
            parentElement: chatClientRef.current,
            packet_code: packetCode,
            app_url: `https://${loadPath}/chat-client/`,
            service_address: servicePath
          }
        }

        const el = document.createElement('script')
        el.async = true
        el.src = `https://${loadPath}/chat-client/chat-loader.js`
        if (window.document.body) {
          window.document.body.appendChild(el)
        }
      }
    }
  }, [profileInfo])

  const parseURL = url => {
    const hashes = url.slice(url.indexOf('?') + 1).split('&')
    const params = {}
    hashes.map(hash => {
      const [key, val] = hash.split('=')
      params[key] = decodeURIComponent(val)
      return 1
    })

    return params
  }

  useEffect(() => {
    const queryParams = parseURL(window.location.search)
    if (queryParams.m_id && profileInfo) {
      handleMeetingSelect(Number.parseInt(queryParams.m_id))
    }
  }, [profileInfo])

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    setTimeZone(tz)
  }, [])

  if (!profileInfo) {
    return <></>
  }

  const hasCalendar = profileInfo.attributes.has_calendar
  const hasProfilePicture = Boolean(profileInfo.attributes.profile_picture_url)

  return (
    <>
      <div className={classes.root} ref={chatClientRef}>
        <div className={classes.container} style={{ marginRight: (contactInfoExpanded && !isMobile) ? '5px' : 'auto' }}>
          <Card raised className={classes.profileCard} style={{ position: 'relative' }}>
            <div className={classes.cardHeader} style={{ backgroundColor: 'black' }}>
            </div>
            <div style={{ display: 'flex', padding: '10px 30px 40px 30px', flexWrap: 'wrap' }}>
              {hasProfilePicture && (
                <img className={classes.profilePicture} src={profileInfo.attributes.profile_picture_url} />
              )}
              <div style={{ flexGrow: 10, marginTop: '55px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <div className={classes.agentName}>
                      {profileInfo.attributes.agent_name}
                    </div>
                    <div className={classes.subtitle}>
                      {profileInfo.attributes?.agent_profile_settings?.contact_details?.position || 'No Title Found'}
                    </div>
                    <div className={classes.subtitle} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
                      <div>
                        <img src={LocationIcon} style={{ marginTop: '5px', height: 22, width: 19, marginRight: 10 }} />
                      </div>
                      <div>
                        {profileInfo?.attributes?.agent_profile_settings?.contact_details?.location}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ padding: '5px', marginRight: '10px', boxShadow: '1px 2px #00000040', borderRadius: '5px' }}>
                        <img
                          src={profileInfo.attributes.company_attributes?.company_logo_url}
                          className={classes.companyPicture}
                        />
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: '14px', color: '#6B7280' }}>
                        {profileInfo.attributes.company_attributes?.company_name}
                      </div>
                    </div>
                    <div onClick={() => setContactInfoExpanded(!contactInfoExpanded)} style={{ display: 'flex', marginTop: '10px', marginLeft: '10px', cursor: 'pointer' }}>
                      <img src={ContactInfoIcon} />
                      <div className={classes.link} style={{ marginLeft: '8px' }}>
                        Contact Info
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 25 }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {profileInfo.attributes.agent_profile_settings?.display_chat_with_me && (
                      <Button
                        variant='contained'
                        color='secondary'
                        size='medium'
                        style={{
                          backgroundColor: '#0CA6F9',
                          color: '#FFFFFF',
                          display: !profileInfo.attributes.agent_profile_settings?.display_chat_with_me ? 'none' : null,
                          width: '115px',
                          height: '38px',
                          marginRight: '20px'
                        }}
                        className={small ? classes.smallScreenButton : classes.button}
                        startIcon={<ChatBubbleIcon />}
                        onClick={() => {
                          window.ChatFunnels._internal.hard_enable()
                        }}
                      >
                        Chat
                      </Button>
                    )}
                    {profileInfo.attributes.agent_profile_settings?.display_calendar && (
                      <Button
                        size='medium'
                        variant='contained'
                        disabled={!hasCalendar}
                        style={
                          hasCalendar ? {
                            border: `1px solid #D1D5DB`,
                            backgroundColor: '#FFFFFF',
                            color: '#374151'
                          }
                            : {}
                        }
                        color='secondary'
                        onClick={handleBookMeetingButtonClick}
                        className={small ? classes.smallScreenButton : classes.button}
                        startIcon={<EventIcon />}
                      >
                        Schedule Meeting
                        {loading &&
                          <CircularProgress
                            size={20} style={{
                              color: profileInfo.attributes.primary_color
                            }}
                            className={classes.circularProgress}
                          />}
                      </Button>
                    )}
                  </div>
                  {profileInfo?.attributes?.agent_profile_settings?.contact_details?.bio && profileInfo?.attributes?.agent_profile_settings?.contact_details?.bio.length > 0 && (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px' }}>
                        <div style={{ fontSize: '14px', color: '#6B7280' }}>
                          About {profileInfo?.attributes?.agent_name?.split(' ')[0]}
                        </div>
                        <div style={{ height: '1px', flexGrow: '3', marginLeft: '20px', margintRight: '10px', backgroundColor: '#E0E0E0' }} />
                      </div>
                      <div style={{ borderRadius: '6px', padding: '10px', marginTop: '10px', backgroundColor: '#F0F0F0', color: '#6B7280', fontSize: '14px' }}>
                        {profileInfo?.attributes?.agent_profile_settings?.contact_details?.bio && profileInfo?.attributes?.agent_profile_settings?.contact_details?.bio?.length > 500 && !aboutExpanded ? profileInfo?.attributes?.agent_profile_settings?.contact_details?.bio?.slice(0, 500) + '... ' : profileInfo?.attributes?.agent_profile_settings?.contact_details?.bio}
                        {profileInfo?.attributes?.agent_profile_settings?.contact_details?.bio && profileInfo?.attributes?.agent_profile_settings?.contact_details?.bio?.length > 500 && (
                          <a
                            style={{ color: '#0CA6F9', cursor: 'pointer' }}
                            onClick={() => setAboutExpanded(!aboutExpanded)}
                          >
                            {aboutExpanded ? ' Read Less' : ' Read More'}
                          </a>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Card>
          {profileInfo.attributes?.agent_profile_settings?.allow_offers && profileInfo.attributes?.agent_profile_settings?.offers?.length > 0 && ( 
            <Card raised className={classes.companyCard}>
              <div className={classes.agentName}>
                Additional Links
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {profileInfo.attributes?.agent_profile_settings?.offers.map((offer, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                    <div style={{ marginRight: 10 }}>
                      <Icon icon={offer.icon} color='#0CA6F9' size='default' />
                    </div>
                    <a href={offer.link} target='_blank' rel='noopener noreferrer' className={classes.link}>
                      {offer.title}
                    </a>
                  </div>
                ))}
              </div>
            </Card>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '50px' }}>
            <div>
              <div style={{ fontSize: '16px', fontWeight: 300, marginBottom: '10px' }}>
                Powered by
              </div>
              <img src={SignalsLogo} height={35} />
            </div>
          </div>
        </div>
        {contactInfoExpanded && !isMobile ? (
          <div className={classes.sidebar}>
            <ExpandedInfo
              profileInfo={profileInfo}
            />
          </div>
        ) : (
          <Dialog PaperProps={{ style: { backgroundColor: 'transparent' } }} open={contactInfoExpanded && isMobile} onClose={() => setContactInfoExpanded(false)} fullWidth maxWidth='xs'>
            <ExpandedInfo
              profileInfo={profileInfo}
            />
          </Dialog>
        )}
        <SimpleDialog
          open={modalOpen}
          profileInfo={profileInfo}
          onClose={() => setModalOpen(false)}
        />
        <FullScreenDialog
          open={meetingSelectorOpen}
          onClose={() => setMeetingSelectorOpen(false)}
          handleMeetingSelect={handleMeetingSelect}
          availabilityError={availabilityError}
          profileInfo={profileInfo}
          meetingInfo={meetingInfo}
          small={small}
          loading={loading}
        />
        <CalendarDialog
          open={calendarModalOpen}
          timezone={timezone}
          small={small}
          medium={medium}
          handleSuccess={(response) => {
            setMeetingSelectorOpen(false)
            setCalendarModalOpen(false)
            setConfirmedDetails(response.data)
            setMeetingBookedModalOpen(true)
          }}
          // hasCalendar={hasCalendar}
          slots={slots}
          date={date}
          changeDate={changeDate}
          availableDates={availableDates}
          profileInfo={profileInfo}
          meetingInfo={meetingInfo}

          onClose={() => {
            setMeetingSelectorOpen(false)
            setCalendarModalOpen(false)
          }}
          onBack={() => {
            setCalendarModalOpen(false)
          }}
        />
        <MeetingBookedDialog
          open={meetingBookedModalOpen}
          onClose={() => {
            setMeetingBookedModalOpen(false)
          }}
          confirmedDetails={confirmedDetails}
          profileInfo={profileInfo}
          small={small}
        />
      </div>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

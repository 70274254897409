import React from 'react'
import ContactIcon from 'url:../img/contact.svg'
import CalendarIcon from 'url:../img/calendar.svg'
import ChartIcon from 'url:../img/chart.svg'
import ChatIcon from 'url:../img/chat.svg'
import GiftIcon from 'url:../img/gift.svg'
import GlobeIcon from 'url:../img/globe.svg'
import GroupIcon from 'url:../img/group.svg'
import Location from 'url:../img/location.svg'
import TicketIcon from 'url:../img/ticket.svg'

const imgMapping = {
  calendar: CalendarIcon,
  chart: ChartIcon,
  chat: ChatIcon,
  contact: ContactIcon,
  gift: GiftIcon,
  globe: GlobeIcon,
  group: GroupIcon,
  location: Location,
  ticket: TicketIcon
}

function Icon({ icon, color='black', size='default' }) {
  const imgRef = imgMapping[icon]
  
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <img src={imgRef} alt='icon' />
    </div>
  )
}

export default Icon